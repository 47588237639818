body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto; /* Allows scrolling */
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%; /* Default to 100% height */
}

.App.full-height {
  height: 100vh; /* Full viewport height when needed */
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
}

.App-logo {
  height: 50px;
}

.App-nav {
  display: flex;
  align-items: center;
}

.App-nav a {
  color: rgb(0, 0, 0);
  margin: 0 10px; /* Reduced margin for less spacing */
  text-decoration: none;
  font-family: spingfang hk, PingFang HK, PingFang SC, Lantinghei SC, Heiti SC, sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
}

.App-nav a:hover {
  text-decoration: underline;
}

.App-nav .separator {
  margin: 0 5px; /* Adjust spacing between the links */
  color: rgb(0, 0, 0);
  font-family: spingfang hk, PingFang HK, PingFang SC, Lantinghei SC, Heiti SC, sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
}

.App-main {
  flex: 1; /* This makes the main content area flexible to grow and shrink */
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  overflow-y: auto; /* Allows vertical scrolling */
  padding: 20px; /* Optional padding for better spacing */
}

.App-store-button {
  display: flex;
  justify-content: center; /* Centers the button horizontally within its container */
  padding-top: 200px;
}

.App-store-button img {
  height: 60px; /* Adjust the size of the app store button as needed */
}

.left-align {
  text-align: left;
}
